import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
import VideoContainer from '../shared/VideoContainer';

// UI
import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import ImageCard from '../shared/ImageCard';
import { CTA } from './CTA';

/**
 * @function VideoAndItemsSection
 * @author RCH010
 * @version 1.0
 * @description Renders a setion that starts with the sectionTitle, then
 * a video an supporting text, video can be at the right or left (isVideoToLeft)
 * Finally, a section of imageCards grid is set with the itemsData prop.
 * @param {Boolean} isVideoToLeft
 * @param {String} sectionTitle
 * @param {String} sectionSupportingText
 * @param {String} videoEmbedID
 * @param {String} videoTitle
 * @param {Array} itemsData
 * @returns {JSX}
 */
const VideoAndItemsSection = ({
  isVideoToLeft,
  sectionTitle,
  sectionSupportingText,
  videoEmbedID,
  videoTitle,
  titleData,
  itemsData,
  showCTA,
  textCTA
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  let direction = 'row-reverse';
  let textPadding = { pr: isMobile ? 0 : 4 };

  if (isVideoToLeft) {
    direction = 'row';
    textPadding = { pl: isMobile ? 0 : 4 };
  }

  return (
    <>
      <Box
        sx={{
          mt: isMobile ? 10 : 25,
          display: 'flex',
          flexDirection: isMobile ? 'column' : direction,
          width: '100%',
          height: '100%',
          px: 3,
        }}>
        {isMobile && (
          <Typography
            variant='h2'
            align='center'
            sx={{
              my: isMobile ? 5 : 10,
            }}>
            {sectionTitle}
          </Typography>
        )}
        <Box
          sx={{
            display: 'block',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
          }}>
          <VideoContainer title={videoTitle} embedID={videoEmbedID} />
        </Box>
        <Box
          sx={{
            maxWidth: isMobile ? 'unset' : '20em',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            pt: isMobile ? 3 : 0,
            ...textPadding,
          }}>
          <Box sx={{ pb: 4 }}>
            {!isMobile && (
              <Typography
                variant='h2'
                align='left'
                sx={{
                  my: 3,
                }}>
                {sectionTitle}
              </Typography>
            )}
            <Typography variant='body1'>{sectionSupportingText}</Typography>
          </Box>
          {showCTA && <CTA textCta={textCTA} />}
        </Box>
      </Box>
      <Box
        sx={{
          px: 3,
          my: 4,
        }}>
        <Typography variant='h6' textAlign='center' sx={{mt: 10}} >{titleData}</Typography> 
        <Grid
          container
          spacing={isMobile ? 0 : 2}
          justifyContent='center'
          alignItems='flex-start'>
          {itemsData.map((items) => (
            <Grid
              key={items.id}
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ height: '100%' }}>
              <ImageCard
                {...items}
                backgroundColor='transparent'
                textColor={theme.palette.common.white}
                justifyContent='flex-start'
                imageMaxWidth={isMobile ? '6em' : '7em'}
                imageContainerHeight='unset'
                textVariant={isMobile ? 'body2' : 'body1'}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

VideoAndItemsSection.defaultProps = {
  isVideoToLeft: true,
  textCTA: 'Renta un palco',
  titleData: ''
};

VideoAndItemsSection.propTypes = {
  isVideoToLeft: PropTypes.bool,
  sectionTitle: PropTypes.string,
  sectionSupportingText: PropTypes.string,
  videoEmbedID: PropTypes.string,
  videoTitle: PropTypes.string,
  itemsData: PropTypes.array,
};

export default VideoAndItemsSection;
