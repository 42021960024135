import React from 'react';
// Components & Utils
import { Box, Button, Stack, Typography } from '@mui/material';
// UI
import notFoundImage from '../assets/others/notFound.svg';
import { useNavigate } from 'react-router-dom';
/**
 * @function NotFound
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: (t) => t.palette.lightLinearGradient,
        minHeight: '100vh',
        width: '100%',
        py: 20,
        display: 'flex',
      }}>
      <Stack
        spacing={{ xs: 3, md: 10 }}
        sx={{
          maxWidth: 'lg',
          mx: 'auto',
          px: 3,
          height: '100%',
          alignSelf: 'center',
        }}
        direction={{ xs: 'column-reverse', md: 'row' }}
        alignItems='center'
        justifyContent='center'>
        <Stack alignItems='center'>
          <Typography
            align='left'
            my={2}
            variant='h1'
            sx={{ width: '100%', fontSize: '7rem !important' }}>
            404
          </Typography>
          <Typography align='left' mb={5} variant='h2'>
            Ups... algo falta aquí
          </Typography>
          <Button
            fullWidth
            onClick={() => {
              navigate('/');
            }}
            color='secondary'
            variant='contained'>
            Regresar al inicio
          </Button>
        </Stack>
        <Box
          sx={{
            '& > img': {
              width: { xs: '12em', md: '100%' },
            },
          }}>
          <img src={notFoundImage} alt='' />
        </Box>
      </Stack>
    </Box>
  );
};

export default NotFound;
