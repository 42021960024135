import { useNavigate } from 'react-router-dom';

// Components & Utils
import { useAddresses } from '../../hooks/requests';

// UI
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useDocumentTitle from '../../hooks/useDocumentTitle';

// This returns an array with six skeleton cards
const ListSkeletons = () => {
  const cards = [];
  for (let index = 0; index < 6; index++) {
    cards.push(
      <Skeleton
        key={index}
        variant='rectangular'
        sx={{
          borderRadius: '16px',
          height: '3rem',
          width: '100%',
        }}
      />
    );
  }
  return cards;
};

export const Addresses = () => {
  useDocumentTitle('Oasis App - Mis direcciones');
  const navigate = useNavigate();
  const { data: addresses } = useAddresses({
    cacheTime: 5000,
    onError: () => console.log('ERROR CARGANDO DIRECCIONES'),
    // onSuccess: (a) => console.log(a),
  });

  if (!addresses) {
    return (
      <Stack spacing={1}>
        <ListSkeletons />
      </Stack>
    );
  }

  return (
    <>
      {addresses.length > 0 ? (
        <>
          <Stack spacing={3}>
            <Stack
              sx={{ width: '100%' }}
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'stretch', md: 'center' }}
              justifyContent='space-between'>
              <Typography variant='h4'>Tus direcciones:</Typography>
              <Box sx={{ alignSelf: 'flex-end' }}>
                <Button
                  sx={{ fontSize: '0.9rem', px: 2 }}
                  size='small'
                  color='secondary'
                  variant='outlined'
                  onClick={() => {
                    navigate(`new`);
                  }}>
                  Nueva dirección
                </Button>
              </Box>
            </Stack>
            <List>
              {addresses.map((address) => (
                <ListItemButton
                  sx={{ borderRadius: 4 }}
                  key={address.id}
                  onClick={() => {
                    navigate(`${address.id}`);
                  }}>
                  <ListItemIcon>
                    <LocationOnIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${address.street}, ${address.extNumber}, ${
                      address.intNumber ? address.intNumber + ',' : ''
                    } ${address.neighborhood}, ${address.zip}, ${
                      address.city
                    }, ${address.state}`}
                  />
                </ListItemButton>
              ))}
            </List>
          </Stack>
        </>
      ) : (
        <Stack spacing={15} justifyContent='space-around' sx={{ py: 4 }}>
          <Stack spacing={1}>
            <Typography variant='h3' textAlign='center'>
              Todavía no cuentas con direcciones
            </Typography>
            <Typography variant='h6' textAlign='center'>
              Añade tu dirección para hacer más rápida tu siguiente renta
            </Typography>
          </Stack>
          <Stack sx={{ width: '100%' }} alignItems='center'>
            <Box>
              <Button
                size='large'
                color='secondary'
                variant='contained'
                onClick={() => {
                  navigate(`new`);
                }}>
                Nueva dirección
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  );
};
