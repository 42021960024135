import React from 'react';
import PropTypes from 'prop-types';
// UI
import { Button, Stack } from '@mui/material';

/**
 * @function SeeSummaryButton
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const SeeSummaryButton = ({ setDrawer, display }) =>
  display ? (
    <Stack alignItems='flex-end' sx={{ display: { xs: 'flex', md: 'none' } }}>
      <Button
        size='small'
        color='secondary'
        variant='text'
        onClick={() => setDrawer(true)}
        sx={{
          width: 'fit-content',
          textTransform: 'none',
          fontSize: '0.9rem',
          my: 1,
        }}>
        Ver resumen
      </Button>
    </Stack>
  ) : (
    <></>
  );

SeeSummaryButton.propTypes = {
  setDrawer: PropTypes.func,
  display: PropTypes.bool,
};

export default SeeSummaryButton;
