import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Reaptcha from 'reaptcha';

// Components & Utils
// UI
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  alpha,
  CircularProgress,
} from '@mui/material';
import TextInput from '../inputs/TextInput';
import { sendContactForm } from '../../api/extras';
import { useMutation } from 'react-query';
import { UIContext } from '../../providers/UIProvider';

const defaultValues = {
  name: null,
  email: null,
  message: null,
};

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

/**
 * @function ContactForm
 * @author RCH010
 * @version 1.0
 * @description contact form
 * @param {String} title section title ('¡Contáctanos!' as default)
 * @param {Boolean} noBackground if given, bgcolor classes will be removed
 * @returns {JSX}
 */
const ContactForm = ({ title, noBackground = false }) => {
  const captcha = useRef(null);
  const sendFormMutation = useMutation(sendContactForm);
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { toggleSnackbar, updateSnackbarProps } = useContext(UIContext);

  const {
    register,
    formState: { errors, isValid, touchedFields },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const onFormSubmit = async (data) => {
    setIsLoading(true);
    const contactForm = {
      ...data,
      message: `
      ${data.message} 
      <br>----------------------------------------------------------------<br>
      Mensaje envaido desde: ${window.location.href}`,
      'g-recaptcha-response': captchaResponse,
    };
    const res = await sendFormMutation.mutateAsync(contactForm);
    if (res.success) {
      updateSnackbarProps({
        severity: 'success',
        text: 'Su mensaje ha sido enviado. Pronto nos pondremos en contacto con usted.',
      });
      captcha.current && captcha.current.reset();
      reset();
    } else {
      updateSnackbarProps({
        severity: 'error',
        text: 'Ha ocurrido un error, por favor inténtelo de nuevo.',
      });
    }
    toggleSnackbar(true);
    setIsLoading(false);
  };

  const onVerify = async (recaptchaResponse) => {
    setCaptchaResponse(recaptchaResponse);
  };

  return (
    <Box
      sx={{
        ...(!noBackground && {
          bgcolor: (theme) => theme.palette.lightBackground,
        }),
        width: '100%',
        zIndex: 1,
      }}>
      <Box
        sx={{
          maxWidth: 'lg',
          width: '100%',
          mx: 'auto',
          px: 3,
          py: 20,
        }}>
        <Paper
          sx={{
            borderRadius: 8,
            bgcolor: (theme) => alpha(theme.palette.mainBackground, 0.6),
            mx: 'auto',
            width: '100%',
            height: '100%',
            maxWidth: { xs: '100%', sm: '100%', md: 'sm' },
            p: 6,
          }}
          variant='elevation'
          elevation={15}>
          <Typography align='center' variant='h2'>
            {title}
          </Typography>
          <Stack
            onSubmit={handleSubmit(onFormSubmit)}
            component='form'
            justifyContent='space-between'
            spacing={1}
            sx={{
              mx: 'auto',
              mt: 5,
              minHeight: '25em',
            }}>
            <Stack spacing={4} justifyContent='space-between'>
              <TextInput
                inputVariant='name'
                register={register}
                registerName='name'
                required='Por favor ingrese su nombre.'
                error={Boolean(errors.name && touchedFields.name)}
                helperText={
                  Boolean(errors.name && touchedFields.name) &&
                  errors.name.message
                }
              />
              <TextInput
                inputVariant='email'
                registerName='email'
                register={register}
                required='Por favor ingrese su correo.'
                error={Boolean(errors.email && touchedFields.email)}
                helperText={
                  Boolean(errors.email && touchedFields.email) &&
                  errors.email.message
                }
              />
              <TextInput
                placeholder=''
                inputVariant='text'
                registerName='message'
                register={register}
                multiline
                label='Mensaje'
                minRows={3}
                required='Por favor describa el proposito del mensaje.'
                error={Boolean(errors.message && touchedFields.message)}
                helperText={
                  Boolean(errors.message && touchedFields.message) &&
                  errors.message.message
                }
              />
              <Reaptcha
                ref={captcha}
                sitekey={reCaptchaKey}
                onVerify={onVerify}
                onExpire={() => setCaptchaResponse(null)}
                onError={() => setCaptchaResponse(null)}
                size='normal'
                theme='dark'
              />
            </Stack>
            <Button
              type='submit'
              color='secondary'
              variant='contained'
              size='large'
              disabled={!isValid || !captchaResponse || isLoading}
              sx={{ textTransform: 'uppercase', fontSize: 18 }}>
              {isLoading ? <CircularProgress size={30} /> : 'Contactar'}
            </Button>
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};

ContactForm.defaultProps = {
  title: '¡Contáctanos!',
};

ContactForm.propTypes = {
  title: PropTypes.string,
};

export default ContactForm;
