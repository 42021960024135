import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components & Utils
import { useBookings } from '../../hooks/requests';
import BookingCard from './BookingCard';

// UI
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import emptyStateImage from '../../assets/emptyStates/emptyDessert.svg';
import useDocumentTitle from '../../hooks/useDocumentTitle';

// This returns an array with six skeleton cards
const bookingsSkeletons = () => {
  const cards = [];
  for (let index = 0; index < 8; index++) {
    cards.push(
      <Skeleton
        key={index}
        variant='rectangular'
        sx={{
          borderRadius: '16px',
          height: { xs: '20em', md: '14em' },
          width: '100%',
          mx: 'auto',
        }}
      />
    );
  }
  return cards;
};

export const Bookings = () => {
  useDocumentTitle('Oasis App - Mis reservas');
  const navigate = useNavigate();

  const { data: bookings } = useBookings({
    cacheTime: 5000,
    onError: () => console.log('ERROR CARGANDO DIRECCIONES'),
  });

  if (!bookings) {
    return <Stack spacing={3}>{bookingsSkeletons()}</Stack>;
  }

  return (
    <Stack spacing={3}>
      {bookings.length > 0 ? (
        bookings.map((booking) => (
          <BookingCard key={booking.id} booking={booking} />
        ))
      ) : (
        <>
          <Stack spacing={1} alignItems='center'>
            <Box
              sx={{
                pt: 2,
                width: 'fit-content',
                '& img': { maxWidth: { xs: '10em', md: '15em' } },
              }}>
              <img src={emptyStateImage} alt='' />
            </Box>
            <Typography variant='h3' textAlign='center'>
              Sin reservas
            </Typography>
            <Typography variant='h6' textAlign='center'>
              ¡Renta un palco para tu evento favorito!
            </Typography>
          </Stack>
          <Stack sx={{ width: '100%' }} alignItems='center'>
            <Box sx={{ width: { xs: '100%', md: 'unset' } }}>
              <Button
                fullWidth
                size='large'
                color='secondary'
                variant='contained'
                onClick={() => {
                  navigate('/events');
                }}>
                Ver eventos
              </Button>
            </Box>
          </Stack>
        </>
      )}
    </Stack>
  );
};
