import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// Components & Utils
// UI
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { CheckoutContext } from '../../providers/CheckoutProvider';

const buttonStyles = {
  color: (t) => t.palette.secondary.contrastText,
  transition: 'color 150ms',
  ':hover': {
    color: (t) => t.palette.grey[600],
  },
};

/**
 * @function ProductButtonModal
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const ProductButtonModal = ({ id, price }) => {
  const { cart, updateCart } = useContext(CheckoutContext);

  const addItem = (increment) => {
    updateCart(id, { quantity: increment, unitPrice: price });
  };

  return (
    <Box sx={{ ml: 'auto', width: 'fit-content', py: 2 }}>
      {!cart[id] || cart[id]?.quantity < 1 ? (
        <Button
          onClick={() => addItem(1)}
          variant='contained'
          color='secondary'
          sx={{
            m: 0,
            maxWidth: '100%',
            height: '40px',
            width: '180px',
            borderRadius: '500px',
            py: '8px',
            px: 2,
            display: 'flex',
          }}>
          Añadir
        </Button>
      ) : (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            maxWidth: '100%',
            width: '180px',
            px: 2,
            backgroundColor: (t) => t.palette.secondary.main,
            borderRadius: '500px',
          }}>
          <>
            <IconButton>
              <>
                {cart[id]?.quantity === 1 ? (
                  <DeleteRoundedIcon
                    onClick={() => addItem(-1)}
                    sx={{
                      ...buttonStyles,
                    }}
                  />
                ) : (
                  <RemoveRoundedIcon
                    onClick={() => addItem(-1)}
                    sx={{
                      ...buttonStyles,
                    }}
                  />
                )}
              </>
            </IconButton>
            <Typography sx={{ color: (t) => t.palette.secondary.contrastText }}>
              {cart[id]?.quantity}
            </Typography>
            <IconButton onClick={() => addItem(1)}>
              <AddRoundedIcon
                sx={{
                  ...buttonStyles,
                }}
              />
            </IconButton>
          </>
        </Stack>
      )}
    </Box>
  );
};

ProductButtonModal.defaultProps = {
  something: '',
};

ProductButtonModal.propTypes = {
  something: PropTypes.string,
};

export default ProductButtonModal;
