import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

/**
 * @function LargeButton
 * @author RCH010
 * @version 1.0
 * @description Really big button
 * @param {node} children
 * @returns {JSX}
 */
const LargeButton = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      variant='contained'
      color='secondary'
      size='large'
      sx={{
        zIndex: 2,
        fontSize: '1.3rem',
        textTransform: 'uppercase',
        borderRadius: 8,
        p: '16px 44px',
        transition: 'box-shadow 200ms, background-color 200ms',
        boxShadow: (currTheme) =>
          `0.1em 0.2em .2em ${currTheme.palette.shadowGreen}`,
        '&:hover': {
          boxShadow: (currTheme) =>
            `0.2em 0.35em .9em ${currTheme.palette.shadowGreen}`,
        },
      }}>
      {children}
    </Button>
  );
};

LargeButton.propTypes = {
  children: PropTypes.node,
};

export default LargeButton;
