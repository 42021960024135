import React from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { menuOptions } from '../../utils/menuOptions';
import { Link } from 'react-router-dom';

export const CTA = ({ isFullWidth, textCta }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Button
      as={Link}
      to={menuOptions.EVENTS.path}
      variant='contained'
      color='secondary'
      fullWidth={isFullWidth}
      size='large'
      sx={{
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'none',
        zIndex: 2,
        positon: 'relative',
        mb: isMobile ? '2em' : '0',
        fontSize: '1.3rem',
        p: '12px 24px',
        mx: '5px',
        borderRadius: '16px',
        transition: 'box-shadow 200ms, background-color 200ms',
      }}>
      {textCta}
    </Button>
  );
};

CTA.defaultProps = {
  isFullWidth: true,
  textCta: 'Renta un palco'
};
