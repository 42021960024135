import React, { useContext } from 'react';

// Components & Utils
import { UIContext } from '../../providers/UIProvider';

// UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction='up' ref={ref} {...props} />;
});

const variantIcons = {
  info: (
    <InfoOutlinedIcon color='info' sx={{ verticalAlign: 'middle', mr: 1 }} />
  ),
  error: (
    <ErrorOutlineOutlinedIcon
      color='error'
      sx={{ verticalAlign: 'middle', mr: 1 }}
    />
  ),
  warning: (
    <WarningAmberRoundedIcon
      color='warning'
      sx={{ verticalAlign: 'middle', mr: 1 }}
    />
  ),
};

/**
 * @function AlertDialog
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const AlertDialog = () => {
  const { isAlertDialogOpen, toggleAlertDialog, alertDialogProps } =
    useContext(UIContext);

  const { title, content, action, variant, ...restProps } = alertDialogProps;

  return (
    <Dialog
      open={isAlertDialogOpen}
      TransitionComponent={Transition}
      onClose={() => toggleAlertDialog(false)}
      PaperProps={{ sx: { borderRadius: '16px', p: 2 } }}
      {...restProps}>
      <DialogTitle>
        {variant && variantIcons[variant]}
        {alertDialogProps.title}
      </DialogTitle>
      <DialogContent>{alertDialogProps.content}</DialogContent>
      <DialogActions>
        {alertDialogProps.action ? (
          alertDialogProps.action
        ) : (
          <Button color='secondary' onClick={() => toggleAlertDialog(false)}>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
