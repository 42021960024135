import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
import { openExternalLink } from '../../../utils/util';
// UI
import { Button, useTheme } from '@mui/material';

/**
 * @function StoreButton
 * @author RCH010
 * @version 1.0
 * @description Button that has an image (stores images)
 * @param {String} something short description of prop
 * @param {String} id of store, used to get externalLink
 * @param {String} altText alt text if image doesn't loads
 * @param {String} image path to image
 * @param {String | Number} imageWidth
 * @returns {JSX}
 */
const StoreButton = ({ id, altText, image, imageWidth }) => {
  const theme = useTheme();
  return (
    <Button
      color='info'
      sx={{
        p: 0,
        height: 'fit-content',
        '& > img': {
          transition: 'max-width 300ms, width 300ms',
          maxWidth: '100%',
          width: imageWidth,
        },
        '&:hover': {
          filter: `drop-shadow(0.2em 0.35em .8em ${theme.palette.shadowBlack})`,
          backgroundColor: 'unset',
          '& > img': {
            maxWidth: '105%',
            width: `calc(${imageWidth} + 1em)`,
          },
        },
      }}
      onClick={() => openExternalLink(id)}>
      <img src={image} alt={altText} />
    </Button>
  );
};

StoreButton.defaultProps = {
  imageWidth: '12em',
};

StoreButton.propTypes = {
  id: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageWidth: PropTypes.string,
};

export default StoreButton;
