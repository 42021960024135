import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  useTheme,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import { navbarOptions } from '../../utils/menuOptions';
import CloseIcon from '@mui/icons-material/Close';
import { CTAVariants } from './Navbar';
import { UIContext } from '../../providers/UIProvider';
import { Link, useNavigate } from 'react-router-dom';

/**
 * @function DrawerMenu
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const DrawerMenu = ({ openMenu, toggleDrawer }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { CTAVariant = 'bookCTA' } = useContext(UIContext);

  return (
    <SwipeableDrawer
      sx={{ zIndex: 2070 }}
      open={openMenu}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      anchor='top'>
      <Box
        sx={{
          backgroundColor: theme.palette.mainBackground,
          py: '1em',
        }}
        role='presentation'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <IconButton
            sx={{ zIndex: 15 }}
            color='secondary'
            onClick={() => toggleDrawer(false)}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
        <List sx={{ mt: -6 }}>
          {navbarOptions.map((option) => (
            <Link
              key={option.id}
              to={`${option.path}`}
              style={{ color: 'inherit', textDecoration: 'none' }}>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(false);
                }}
                key={option.id}
                sx={{
                  px: '2em',
                  ':hover': {
                    backgroundColor: theme.palette.darkerBackground,
                  },
                }}>
                <ListItemText>
                  <Typography align='left' variant='body2'>
                    {option.label}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Link>
          ))}
        </List>
        <Box sx={{ mx: 5 }}>
          <Button
            fullWidth
            color='secondary'
            onClick={() => {
              toggleDrawer(false);
              navigate(CTAVariants[CTAVariant].path);
            }}
            {...CTAVariants[CTAVariant]}
          />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

DrawerMenu.defaultProps = {
  openMenu: false,
  toggleDrawer: null,
};

DrawerMenu.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default DrawerMenu;
