import React from 'react';
// Components & Utils
import TestimonyCard from './TestimonyCard';
import Carousel from '../carousel/Carousel';

// UI
import { Box, Typography, useTheme } from '@mui/material';
import QuarterCircleSVG from '../../assets/others/quarterCircle.svg';
import { getResponsiveObjectForTestimonies } from '../../utils/carouselResponsive';
import testimonies from '../../utils/json/testimonies.json';

/**
 * @function TestimoniesSections
 * @author RCH010
 * @version 1.0
 * @description Section that has a carousel with the given testimonies.
 * @returns {JSX}
 */
const TestimoniesSections = () => {
  const theme = useTheme();
  const responsiveCarouselBreakpoints =
    getResponsiveObjectForTestimonies(theme);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        bgcolor: (currTheme) => currTheme.palette.lightBackground,
      }}>
      <img
        src={QuarterCircleSVG}
        alt=''
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          zIndex: 0,
          maxWidth: '100%',
        }}
      />
      <Box
        sx={{
          maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
          mx: 'auto',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 20,
        }}>
        <Typography align='left' variant='h2' sx={{ px: 3, mb: 4 }}>
          Testimonios
        </Typography>

        <Carousel
          autoPlaySpeed={5000}
          carouselItems={testimonies}
          Item={TestimonyCard}
          responsive={responsiveCarouselBreakpoints}
        />
      </Box>
    </Box>
  );
};

export default TestimoniesSections;
